@import url('https://fonts.googleapis.com/css2?family=Anton&family=Monoton&family=Nunito:wght@200;400;700&family=Roboto:wght@100;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card{
  margin-top:1.5em;
  margin-right:.3em;
  margin-left:.3em;

}

h3{
  font-family:'Anton',cursive;
}

.loading{
  margin: auto;
  z-index: 99;
  width: 10%;
}

.prettyImg{
    position: relative;
    margin: 0 auto;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.2;
}